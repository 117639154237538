import { createElement } from "react";
import * as react from "react";
import { int32ToString, createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton as singleton_1, ofArray } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { Avatar, Tooltip, Skeleton, CircularProgress, Snackbar, Alert } from "@mui/material";
import { defaultOf } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { format, printf, toText } from "./fable_modules/fable-library-js.4.17.0/String.js";
import { StockRating, StockRating__ToTextColorCode, StockRating__ToColorCode, StockRating_FromInt_Z524259A4 } from "./Shared/Shared.js";
import { Record, Union, toString } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { Analytics_AssetCount_$reflection } from "./Shared/ApiDataTypes.js";
import { record_type, int32_type, string_type, union_type, array_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { map } from "./fable_modules/fable-library-js.4.17.0/Array.js";

export function errorMsg(msg) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
        display: "flex",
        justifyContent: "center",
        marginBottom: 5 + "px",
    }], (elems_1 = [createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
        textAlign: "center",
        padding: 5 + "px",
        borderRadius: 5 + "px",
        backgroundColor: "#eb746a",
    }], (elems = [createElement("span", {
        style: {
            color: "#f6f6f6",
        },
        children: msg,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function errorAlert(errMsg, dismiss) {
    if (errMsg != null) {
        const err = errMsg;
        return MuiHelpers_createElement(Alert, [["style", {
            margin: ((5 + "px ") + 0) + "px",
        }], ["severity", "error"], ["onClose", dismiss], ["children", Interop_reactApi.Children.toArray([err])]]);
    }
    else {
        return defaultOf();
    }
}

export function snackError(errMsg, dismiss) {
    return MuiHelpers_createElement(Snackbar, [["anchorOrigin", {
        vertical: "bottom",
        horizontal: "center",
    }], ["open", errMsg != null], ["onClose", (_arg_1, v) => {
        dismiss();
    }], ["autoHideDuration", 20000], ["children", MuiHelpers_createElement(Alert, [["severity", "error"], ["variant", "filled"], ["onClose", (_arg_2) => {
        dismiss();
    }], ["children", (errMsg == null) ? "" : errMsg]])]]);
}

export function nbText(txt) {
    return createElement("span", {
        style: {
            fontSize: 0.7 + "rem",
            fontStyle: "italic",
        },
        children: txt,
    });
}

export function loadingIndicatorSmall() {
    return MuiHelpers_createElement(CircularProgress, [["style", {
        width: 20,
        height: 20,
        color: "var(--text-main)",
    }]]);
}

export function loadingIndicatorSmallCentered() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        textAlign: "center",
        width: 100 + "%",
    }], (elems = [MuiHelpers_createElement(CircularProgress, [["style", {
        width: 20,
        height: 20,
        color: "var(--text-main)",
    }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function loadingIndicator() {
    return MuiHelpers_createElement(CircularProgress, [["style", {
        width: 30,
        height: 30,
        color: "var(--text-main)",
    }]]);
}

export function loadingIndicatorLarge() {
    return MuiHelpers_createElement(CircularProgress, [["style", {
        width: 40,
        height: 40,
        color: "var(--text-main)",
    }]]);
}

export function postLoadingIndicator() {
    return MuiHelpers_createElement(Skeleton, [["variant", "rectangular"], ["style", {
        height: 248,
        width: 100 + "%",
    }]]);
}

export function limitedText(txt, maxLength) {
    if (txt.length > maxLength) {
        const sub = txt.slice(0, (maxLength - 3) + 1);
        return toText(printf("%s..."))(sub);
    }
    else {
        return txt;
    }
}

export function wrapTooltip(x, y) {
    return MuiHelpers_createElement(Tooltip, [["title", x], ["children", y]]);
}

export function handleNumber(num) {
    let x_1;
    if (num < 1000) {
        return int32ToString(num);
    }
    else if ((x_1 = (num | 0), (x_1 >= 1000) && (x_1 < 1000000))) {
        const y = num / 1000;
        const arg = format('{0:' + "0.0" + '}', y);
        return toText(printf("%sk"))(arg);
    }
    else {
        const y_1 = num / 1000000;
        const arg_1 = format('{0:' + "0.0" + '}', y_1);
        return toText(printf("%sm"))(arg_1);
    }
}

export function textCounter(num, maxNum) {
    return createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
            fontStyle: "italic",
            color: (num > maxNum) ? "#cc3608" : "#bababa",
            textAlign: "left",
        },
        children: toText(printf("%i/%i"))(num)(maxNum),
    });
}

export function smallIconButton(className, onClick) {
    let elems;
    return createElement("button", createObj(ofArray([["style", {
        cursor: "pointer",
        margin: 0,
        marginRight: 4,
        padding: 0,
        border: "none",
        background: "none",
        height: 18,
        width: 18,
    }], ["onClick", onClick], (elems = [createElement("i", {
        className: className,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function pillButtonSmall(styles, title, onClick) {
    let elems;
    return createElement("button", createObj(ofArray([["className", "pill-button-small"], ["style", createObj(styles)], ["onClick", onClick], (elems = [createElement("span", {
        style: {
            textAlign: "center",
        },
        children: title,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function pillButton(styles, title, onClick) {
    return createElement("button", {
        onClick: onClick,
        className: "pill-button",
        children: title,
    });
}

export function groupButtonLeft(isActive, onClick, children) {
    return createElement("button", {
        className: isActive ? "grouped-button-left grouped-button-active" : "grouped-button-left",
        onClick: onClick,
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function groupButtonCenter(isActive, onClick, children) {
    return createElement("button", {
        className: isActive ? "grouped-button-center grouped-button-active" : "grouped-button-center",
        onClick: onClick,
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function groupButtonRight(isActive, onClick, children) {
    return createElement("button", {
        className: isActive ? "grouped-button-right grouped-button-active" : "grouped-button-right",
        onClick: onClick,
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function tooltipHeader(header, tooltipTitle) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
    }], (elems = [createElement("span", {
        style: {
            color: "var(--text-main)",
            fontStyle: "italic",
            paddingLeft: 7,
        },
        children: header,
    }), MuiHelpers_createElement(Tooltip, [["title", tooltipTitle], ["children", createElement("span", {
        style: {
            color: "var(--text-dim)",
            marginLeft: 7,
        },
        className: "fas fa-question-circle",
    })]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function assetListHolder(children) {
    return createElement("div", {
        style: {
            marginBottom: 15,
            marginRight: 15,
            maxWidth: 300,
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function assetListEntry(ticker, name, link) {
    let elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["className", "preview-listing-holder"], (elems_2 = [createElement("div", createObj(ofArray([["className", "preview-listing-name"], (elems = [createElement("a", {
        href: link,
        children: name,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "preview-listing-ticker"], (elems_1 = [createElement("a", {
        href: link,
        children: ticker,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function assetListEntryLoading() {
    let elems;
    return createElement("div", createObj(ofArray([["className", "preview-listing-holder"], (elems = [MuiHelpers_createElement(Skeleton, [["variant", "rectangular"], ["style", {
        height: "inherit",
        width: 100 + "%",
    }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Image_userCircle(userId, size, isLarge, randHash) {
    let arg_1, arg_2;
    return MuiHelpers_createElement(Avatar, [["width", size], ["height", size], ["style", {
        width: size,
        height: size,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
    }], ["alt", "User image"], ["src", (arg_1 = (isLarge ? "normal" : "small"), (arg_2 = ((randHash != null) ? (`&r=${randHash}`) : ""), toText(printf("/api/user/image/%i?size=%s%s"))(userId)(arg_1)(arg_2)))]]);
}

export function Image_circledImage(size, imgUrl) {
    return MuiHelpers_createElement(Avatar, [["width", size], ["height", size], ["style", {
        width: size,
        height: size,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
    }], ["alt", "Image"], ["src", imgUrl]]);
}

export function AssetSummary_reviewBar(r, isSmall) {
    let elems_5, elems, elems_4;
    const rating = StockRating_FromInt_Z524259A4(r.Rating);
    const a = createElement("div", createObj(ofArray([["className", isSmall ? "review-listing-holder-small" : "review-listing-holder"], ["style", {
        margin: 5,
    }], (elems_5 = [createElement("div", createObj(ofArray([["className", isSmall ? "review-listing-rating-small" : "review-listing-rating"], ["style", {
        backgroundColor: StockRating__ToColorCode(rating),
        minWidth: 120,
        textAlign: "center",
    }], (elems = [createElement("span", {
        style: {
            color: StockRating__ToTextColorCode(rating),
        },
        children: toString(rating),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between",
    }], (elems_4 = toList(delay(() => {
        let elems_1;
        return append(singleton(createElement("div", createObj(ofArray([["style", createObj(toList(delay(() => append(singleton(["display", "flex"]), delay(() => append(singleton(["justifyContent", "center"]), delay(() => append(singleton(["alignItems", "center"]), delay(() => (isSmall ? singleton(["padding", 5]) : singleton(["padding", ((((((5 + "px ") + 5) + "px ") + 5) + "px ") + 15) + "px"])))))))))))], (elems_1 = [createElement("span", {
            style: createObj(toList(delay(() => append(singleton(["fontWeight", "bold"]), delay(() => (isSmall ? singleton(["fontSize", 1 + "rem"]) : singleton(["fontSize", 1.2 + "rem"]))))))),
            children: (r.AssetType === 2) ? r.AssetTicker : toText(printf("%s.%s"))(r.AssetTicker)(r.MarketTicker),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
            let elems_2;
            return append(singleton(createElement("div", createObj(ofArray([["style", createObj(toList(delay(() => append(singleton(["display", "flex"]), delay(() => append(singleton(["flexDirection", "column"]), delay(() => append(singleton(["padding", 5]), delay(() => append(!isSmall ? singleton(["paddingLeft", 15]) : empty(), delay(() => append(singleton(["justifyContent", "center"]), delay(() => singleton(["textAlign", "center"]))))))))))))))], (elems_2 = toList(delay(() => {
                const matchValue = r.TargetPrice;
                if (matchValue != null) {
                    const tp = matchValue;
                    return append(singleton(createElement("span", {
                        style: {
                            color: "var(--text-dim)",
                            fontSize: 0.7 + "rem",
                        },
                        children: "Target price",
                    })), delay(() => {
                        let arg_3;
                        return singleton(createElement("span", {
                            style: createObj(toList(delay(() => append(singleton(["whiteSpace", "nowrap"]), delay(() => (isSmall ? singleton(["fontSize", 0.8 + "rem"]) : empty())))))),
                            children: (arg_3 = format('{0:' + "0.00" + '}', tp), toText(printf("%s %s"))(r.Currency)(arg_3)),
                        }));
                    }));
                }
                else {
                    return singleton(createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: "No target price",
                    }));
                }
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_3;
                return !isSmall ? singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    alignItems: "center",
                    padding: ((((((5 + "px ") + 5) + "px ") + 5) + "px ") + 15) + "px",
                }], (elems_3 = [Image_userCircle(r.UserId, 40, false, undefined)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))) : empty();
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
    return createElement("a", {
        href: toText(printf("/post?src=rv&sid=%i"))(r.ReviewId),
        children: Interop_reactApi.Children.toArray([a]),
    });
}

export class AssetSummary_AssetListingSource extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ReviewCount"];
    }
}

export function AssetSummary_AssetListingSource_$reflection() {
    return union_type("Investfora.ViewHelpers.AssetSummary.AssetListingSource", [], AssetSummary_AssetListingSource, () => [[["Item", array_type(Analytics_AssetCount_$reflection())]]]);
}

export function AssetSummary_drawReviewTable(x) {
    let elems_2, children_6;
    return createElement("table", createObj(ofArray([["className", "top-table"], (elems_2 = [createElement("thead", {}), (children_6 = toList(delay(() => append(map((y) => {
        let children, elems, elems_1;
        const rating = StockRating_FromInt_Z524259A4(y.Rating);
        const children_2 = ofArray([(children = singleton_1(createElement("a", {
            className: (rating.tag === 1) ? "top-table-weak-buy" : ((rating.tag === 2) ? "top-table-hold" : ((rating.tag === 3) ? "top-table-weak-sell" : ((rating.tag === 4) ? "top-table-sell" : "top-table-buy"))),
            children: toString(rating),
            href: toText(printf("/post?src=rv&sid=%i"))(y.ReviewId),
        })), createElement("td", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("td", createObj(singleton_1((elems = toList(delay(() => ((y.AssetType === 1) ? singleton(createElement("a", {
            href: toText(printf("/stock/%s/%s"))(y.MarketTicker)(y.AssetTicker),
            children: toText(printf("%s.%s"))(y.AssetTicker)(y.MarketTicker),
        })) : singleton(createElement("a", {
            href: toText(printf("/crypto/%s"))(y.AssetTicker),
            children: y.AssetTicker,
        }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("td", createObj(ofArray([["className", "top-table-right"], (elems_1 = [createElement("a", {
            href: toText(printf("/user/%s"))(y.Username),
            children: y.Username,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]);
        return createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
    }, x), delay(() => {
        let children_4;
        return (x.length === 0) ? singleton((children_4 = singleton_1(createElement("td", {
            colSpan: 3,
            style: {
                textAlign: "center",
            },
            children: "None available",
        })), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))) : empty();
    })))), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function AssetSummary_drawReviewCountTable(x) {
    let elems_1, children_4;
    return createElement("table", createObj(ofArray([["className", "top-table"], (elems_1 = [createElement("thead", {}), (children_4 = toList(delay(() => append(map((entry) => {
        let elems, arg_5;
        const children = ofArray([createElement("td", createObj(singleton_1((elems = toList(delay(() => ((entry.AssetType === 1) ? singleton(createElement("a", {
            href: toText(printf("/stock/%s/%s"))(entry.MarketTicker)(entry.AssetTicker),
            children: toText(printf("%s.%s"))(entry.AssetTicker)(entry.MarketTicker),
        })) : singleton(createElement("a", {
            href: toText(printf("/crypto/%s"))(entry.AssetTicker),
            children: entry.AssetTicker,
        }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("td", {
            className: "top-table-right",
            children: (arg_5 = handleNumber(entry.Num), toText(printf("%s reviews"))(arg_5)),
        })]);
        return createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }, x), delay(() => {
        let children_2;
        return (x.length === 0) ? singleton((children_2 = singleton_1(createElement("td", {
            colSpan: 2,
            style: {
                textAlign: "center",
            },
            children: "None available",
        })), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))) : empty();
    })))), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function AssetSummary_reviewSlider(x) {
    const xs_2 = [createElement("input", {
        type: "range",
        max: 100,
        min: 0,
        style: {
            background: "none",
            "box-shadow": "none",
            padding: 0,
            border: "none",
            width: 100 + "%",
        },
        className: "review-slider",
        value: x,
        disabled: true,
    }), createElement("div", {
        style: {
            width: 100 + "%",
            height: 7,
            backgroundImage: "linear-gradient(to right, #cc3608, #cc3608, #f28574, #e6f7f6, #e6f7f6, #92f274, #36cc08, #36cc08)",
            marginBottom: 15,
        },
    })];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

export function AssetSummary_averageSlider(x) {
    if (x != null) {
        const y = x;
        const children = toList(delay(() => {
            if (y.Num > 0) {
                const rating = StockRating_FromInt_Z524259A4(y.Average);
                return append(singleton(createElement("span", {
                    style: {
                        color: StockRating__ToColorCode(rating),
                        fontSize: 2.5 + "rem",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                    },
                    children: toString(rating),
                })), delay(() => append(singleton(AssetSummary_reviewSlider(y.Average)), delay(() => singleton(createElement("span", {
                    style: {
                        fontSize: 0.6 + "rem",
                        fontStyle: "italic",
                    },
                    children: toText(printf("Based on %i reviews"))(y.Num),
                }))))));
            }
            else {
                return singleton(createElement("span", {
                    children: "Not enough data to calculate average",
                }));
            }
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        return loadingIndicatorSmall();
    }
}

export function AssetSummary_ratingTops(x) {
    let elems_3;
    if (x != null) {
        const y = x;
        const topEntry = (e, r) => {
            let elems_2, elems;
            let e$0027;
            if (e != null) {
                const y_1 = e;
                const link = (y_1.Type === 1) ? toText(printf("/stock/%s/%s"))(y_1.Market)(y_1.Ticker) : toText(printf("/crypto/%s"))(y_1.Ticker);
                const name_2 = (y_1.Type === 1) ? toText(printf("%s (%s.%s)"))(y_1.Name)(y_1.Ticker)(y_1.Market) : toText(printf("%s (%s)"))(y_1.Name)(y_1.Ticker);
                e$0027 = createElement("a", {
                    href: link,
                    style: {
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        fontSize: 0.9 + "rem",
                    },
                    children: name_2,
                });
            }
            else {
                e$0027 = createElement("span", {
                    style: {
                        fontStyle: "italic",
                    },
                    children: "None",
                });
            }
            return createElement("div", createObj(ofArray([["className", "review-listing-holder"], ["style", {
                margin: 5,
            }], (elems_2 = [createElement("div", createObj(ofArray([["className", "review-listing-rating"], ["style", {
                backgroundColor: StockRating__ToColorCode(r),
                minWidth: 120,
                textAlign: "center",
            }], (elems = [createElement("span", {
                style: {
                    color: StockRating__ToTextColorCode(r),
                },
                children: toString(r),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 5,
                    overflowX: "hidden",
                },
                children: Interop_reactApi.Children.toArray([e$0027]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
        };
        return createElement("div", createObj(ofArray([["style", {}], (elems_3 = [topEntry(y.Buy, new StockRating(0, [])), topEntry(y.WeakBuy, new StockRating(1, [])), topEntry(y.Hold, new StockRating(2, [])), topEntry(y.WeakSell, new StockRating(3, [])), topEntry(y.Sell, new StockRating(4, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
    }
    else {
        return loadingIndicatorSmall();
    }
}

class AssetSummary_PieData extends Record {
    constructor(name, value) {
        super();
        this.name = name;
        this.value = (value | 0);
    }
}

function AssetSummary_PieData_$reflection() {
    return record_type("Investfora.ViewHelpers.AssetSummary.PieData", [], AssetSummary_PieData, () => [["name", string_type], ["value", int32_type]]);
}

export function User_userRow(uId, username, children) {
    let elems_3, elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#FFFFFF",
        maxWidth: 400,
        padding: ((((((3 + "px ") + 6) + "px ") + 3) + "px ") + 6) + "px",
        width: 100 + "%",
        marginBottom: 7,
    }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
    }], (elems_2 = [createElement("a", createObj(ofArray([["href", toText(printf("/user/%s"))(username)], (elems = [Image_userCircle(uId, 40, false, undefined)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("a", createObj(ofArray([["href", toText(printf("/user/%s"))(username)], ["style", {
        marginLeft: 10,
    }], (elems_1 = [createElement("span", {
        children: [username],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function User_usernameText(x, postRemoved) {
    if (!postRemoved) {
        const xs_3 = toList(delay(() => append(singleton(createElement("a", {
            href: `/user/${x.Username}`,
            children: createElement("span", {
                style: {
                    color: "var(--bg-dark)",
                    fontWeight: 500,
                },
                children: x.Username,
            }),
        })), delay(() => (x.Official ? singleton(createElement("span", {
            style: {
                marginLeft: 5,
                color: "var(--text-main)",
                fontSize: 0.8 + "rem",
            },
            className: "fas fa-shield-alt",
        })) : empty())))));
        return react.createElement(react.Fragment, {}, ...xs_3);
    }
    else {
        return createElement("span", {
            children: x.Username,
        });
    }
}

