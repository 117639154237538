import { Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { float64_type, obj_type, record_type, string_type, option_type, array_type, bool_type, int32_type, union_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { compare, parse, addDays, addYears, now, addMonths, toString } from "../fable_modules/fable-library-js.4.17.0/Date.js";
import { ShareSplit_$reflection, Quote_MovingAverageSummary_$reflection, DataServer_Quote_QuoteSummary_$reflection, Quote_Quote_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { sortBy, map as map_1, maxBy } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { equals, createObj, uncurry2, comparePrimitives } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { toString as toString_1 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { DataServerBaseUrl } from "../Session.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ResponsiveContainer, ComposedChart, Bar, LineChart, ReferenceLine, Label, Line, Legend, Tooltip, YAxis, XAxis } from "recharts";
import { toShortAmount, quoteToString, toPositivePercentageChange, shortPercentageToString, utcDateToLocalDate } from "../Utils.js";
import { format } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { createElement } from "react";
import React from "react";
import { snackError, wrapTooltip, groupButtonRight, groupButtonLeft } from "../ViewHelpers.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { MenuItem, Select, InputLabel, FormControl, TableBody, TableHead, TableRow, Table, Skeleton, TableCell } from "@mui/material";
import { Dimensions_TextSmall } from "../Design.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.2.7.0/React.fs.js";

class ReportPeriod extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Week", "Month", "Quarter", "HalfYear", "Year", "TwoYears"];
    }
}

function ReportPeriod_$reflection() {
    return union_type("Investfora.AssetQuote.ReportPeriod", [], ReportPeriod, () => [[], [], [], [], [], []]);
}

function ReportPeriod_FromInt_Z524259A4(x) {
    switch (x) {
        case 2:
            return new ReportPeriod(1, []);
        case 3:
            return new ReportPeriod(2, []);
        case 4:
            return new ReportPeriod(3, []);
        case 5:
            return new ReportPeriod(4, []);
        case 6:
            return new ReportPeriod(5, []);
        default:
            return new ReportPeriod(0, []);
    }
}

function ReportPeriod__ToInt(this$) {
    switch (this$.tag) {
        case 1:
            return 2;
        case 2:
            return 3;
        case 3:
            return 4;
        case 4:
            return 5;
        case 5:
            return 6;
        default:
            return 1;
    }
}

function ReportPeriod__ToHttpVar(this$) {
    return `?from=${toString((this$.tag === 1) ? addMonths(now(), -1) : ((this$.tag === 2) ? addMonths(now(), -3) : ((this$.tag === 3) ? addMonths(now(), -6) : ((this$.tag === 4) ? addYears(now(), -1) : ((this$.tag === 5) ? addYears(now(), -2) : addDays(now(), -7))))), "yyyy-MM-dd")}`;
}

class ReportPresentation extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QuotesOnly", "QuoteAndVolume"];
    }
}

function ReportPresentation_$reflection() {
    return union_type("Investfora.AssetQuote.ReportPresentation", [], ReportPresentation, () => [[], []]);
}

class Model extends Record {
    constructor(AssetId, SelectedPeriod, Presentation, MissingInfo, Entries, LastQuote, Summary, MovingAverage, Splits, ErrorMsg) {
        super();
        this.AssetId = (AssetId | 0);
        this.SelectedPeriod = SelectedPeriod;
        this.Presentation = Presentation;
        this.MissingInfo = MissingInfo;
        this.Entries = Entries;
        this.LastQuote = LastQuote;
        this.Summary = Summary;
        this.MovingAverage = MovingAverage;
        this.Splits = Splits;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.AssetQuote.Model", [], Model, () => [["AssetId", int32_type], ["SelectedPeriod", ReportPeriod_$reflection()], ["Presentation", ReportPresentation_$reflection()], ["MissingInfo", bool_type], ["Entries", option_type(array_type(Quote_Quote_$reflection()))], ["LastQuote", option_type(Quote_Quote_$reflection())], ["Summary", option_type(DataServer_Quote_QuoteSummary_$reflection())], ["MovingAverage", option_type(Quote_MovingAverageSummary_$reflection())], ["Splits", array_type(ShareSplit_$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["EntriesResponse", "SummaryResponse", "MovingAverageResponse", "SplitsResponse", "UpdatePeriod", "UpdatePresentation", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.AssetQuote.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Quote_Quote_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Quote_Quote_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataServer_Quote_QuoteSummary_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", DataServer_Quote_QuoteSummary_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Quote_MovingAverageSummary_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Quote_MovingAverageSummary_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ShareSplit_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ShareSplit_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", ReportPeriod_$reflection()]], [["Item", ReportPresentation_$reflection()]], []]);
}

function init(assetId) {
    return new Model(assetId, new ReportPeriod(2, []), new ReportPresentation(0, []), false, undefined, undefined, undefined, undefined, [], undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.AssetId, model.SelectedPeriod, model.Presentation, model.MissingInfo, model.Entries, model.LastQuote, model.Summary, model.MovingAverage, model.Splits, "Kunne ikke laste kursoppsummering. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.AssetId, model.SelectedPeriod, model.Presentation, model.MissingInfo, model.Entries, model.LastQuote, res_1.fields[0], model.MovingAverage, model.Splits, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.AssetId, model.SelectedPeriod, model.Presentation, model.MissingInfo, model.Entries, model.LastQuote, model.Summary, res_2.fields[0], model.Splits, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return model;
            }
            else {
                return new Model(model.AssetId, model.SelectedPeriod, model.Presentation, model.MissingInfo, model.Entries, model.LastQuote, model.Summary, model.MovingAverage, res_3.fields[0], model.ErrorMsg);
            }
        }
        case 4:
            return new Model(model.AssetId, msg.fields[0], model.Presentation, model.MissingInfo, model.Entries, model.LastQuote, model.Summary, model.MovingAverage, model.Splits, model.ErrorMsg);
        case 5:
            return new Model(model.AssetId, model.SelectedPeriod, msg.fields[0], model.MissingInfo, model.Entries, model.LastQuote, model.Summary, model.MovingAverage, model.Splits, model.ErrorMsg);
        case 6:
            return new Model(model.AssetId, model.SelectedPeriod, model.Presentation, model.MissingInfo, model.Entries, model.LastQuote, model.Summary, model.MovingAverage, model.Splits, undefined);
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.AssetId, model.SelectedPeriod, model.Presentation, model.MissingInfo, model.Entries, model.LastQuote, model.Summary, model.MovingAverage, model.Splits, "Kunne ikke laste kursutvikling. Vennligst prøv igjen senere.");
            }
            else {
                const x = res.fields[0];
                return new Model(model.AssetId, model.SelectedPeriod, model.Presentation, model.MissingInfo, x, (x.length === 0) ? model.LastQuote : maxBy((y) => y.Date, x, {
                    Compare: comparePrimitives,
                }), model.Summary, model.MovingAverage, model.Splits, model.ErrorMsg);
            }
        }
    }
}

function fetchQuotes(assetId, period, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const p = ReportPeriod__ToHttpVar(period);
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Quote_Quote_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/asset/quote/${assetId}${p}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Quote_Quote_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchSummary(market, ticker, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(DataServer_Quote_QuoteSummary_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/quote/asset-summary/${market}/${ticker}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(DataServer_Quote_QuoteSummary_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(1, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchMovingAverage(market, ticker, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(Quote_MovingAverageSummary_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/quote/ma-summary/${market}/${ticker}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Quote_MovingAverageSummary_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(2, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchSplits(market, ticker, period, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const p = ReportPeriod__ToHttpVar(period);
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(ShareSplit_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/asset/quote/splits/${market}/${ticker}${p}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(ShareSplit_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(3, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

class Point extends Record {
    constructor(Dato, Kurs, Volum) {
        super();
        this.Dato = Dato;
        this.Kurs = Kurs;
        this.Volum = (Volum | 0);
    }
}

function Point_$reflection() {
    return record_type("Investfora.AssetQuote.Point", [], Point, () => [["Dato", string_type], ["Kurs", float64_type], ["Volum", int32_type]]);
}

function quotesChart(data, splits) {
    let elements;
    const properties_7 = ofArray([["width", 450], ["height", 300], ["data", data], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(5, 0),
        left: defaultArg(10, 0),
        bottom: defaultArg(20, 0),
    }], (elements = toList(delay(() => append(singleton(Interop_reactApi.createElement(XAxis, {
        dataKey: "Dato",
        tickFormatter: (tick) => (`${utcDateToLocalDate(tick)}`),
    })), delay(() => {
        let properties_1;
        return append(singleton((properties_1 = ofArray([["orientation", "right"], ["tickFormatter", (tick_1) => (`${format('{0:' + "0.00" + '}', tick_1)}`)], ["domain", ["auto", "auto"]]]), Interop_reactApi.createElement(YAxis, createObj(properties_1)))), delay(() => append(singleton(Interop_reactApi.createElement(Tooltip, {})), delay(() => append(singleton(Interop_reactApi.createElement(Legend, {})), delay(() => append(singleton(Interop_reactApi.createElement(Line, {
            dataKey: "Kurs",
            stroke: "#5F5566",
            strokeWidth: 3,
            dot: false,
        })), delay(() => map_1((s) => {
            let properties_5;
            const properties_6 = ofArray([["x", s.Date], ["stroke", "#58a4b0"], ["strokeWidth", 2], ["children", (properties_5 = ofArray([["position", "insideTopRight"], ["value", (s.FactorBase > s.FactorChange) ? (`Spleis ${s.FactorBase}:${s.FactorChange}`) : (`Splitt ${s.FactorBase}:${s.FactorChange}`)]]), Interop_reactApi.createElement(Label, createObj(properties_5)))]]);
            return Interop_reactApi.createElement(ReferenceLine, createObj(properties_6));
        }, splits)))))))));
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]);
    return Interop_reactApi.createElement(LineChart, createObj(properties_7));
}

function combinedChart(data) {
    let elements;
    const properties_7 = ofArray([["width", 450], ["height", 300], ["data", data], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(10, 0),
        left: defaultArg(10, 0),
        bottom: defaultArg(20, 0),
    }], (elements = ofArray([Interop_reactApi.createElement(XAxis, {
        dataKey: "Dato",
        tickFormatter: (tick) => (`${utcDateToLocalDate(tick)}`),
    }), Interop_reactApi.createElement(YAxis, {
        yAxisId: "left",
        tickFormatter: (tick_1) => {
            if (tick_1 >= 1000000000) {
                const bn = tick_1 / 1000000000;
                return `${format('{0:' + "0.0" + '}', bn)} mrd`;
            }
            else if (tick_1 >= 1000000) {
                const mill = tick_1 / 1000000;
                return `${format('{0:' + "0.0" + '}', mill)}m`;
            }
            else if (tick_1 >= 1000) {
                const k = tick_1 / 1000;
                return `${format('{0:' + "0.0" + '}', k)}k`;
            }
            else {
                return `${tick_1}`;
            }
        },
    }), Interop_reactApi.createElement(YAxis, {
        yAxisId: "right",
        orientation: "right",
        tickFormatter: (tick_2) => (`${format('{0:' + "0.00" + '}', tick_2)}`),
    }), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(Legend, {}), Interop_reactApi.createElement(Bar, {
        dataKey: "Volum",
        fill: "#73AB84",
        yAxisId: "left",
    }), Interop_reactApi.createElement(Line, {
        dataKey: "Kurs",
        stroke: "#5F5566",
        strokeWidth: 3,
        yAxisId: "right",
        dot: false,
    })]), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]);
    return Interop_reactApi.createElement(ComposedChart, createObj(properties_7));
}

function drawGraph(x, p, splits, dispatch) {
    let elems_2, elems, properties_2, elems_1;
    const data = map_1((y_2) => (new Point(y_2.Date, y_2.Close, y_2.Volume)), sortBy((y) => parse(y.Date), x, {
        Compare: compare,
    }));
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_2 = [createElement("div", createObj(ofArray([["style", {
        minHeight: 210,
        height: 20 + "vh",
    }], (elems = [(properties_2 = ofArray([["minWidth", 250], ["minHeight", 210], ["children", (p.tag === 1) ? combinedChart(data) : quotesChart(data, splits)]]), Interop_reactApi.createElement(ResponsiveContainer, createObj(properties_2)))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
    }], (elems_1 = [groupButtonLeft(equals(p, new ReportPresentation(0, [])), (_arg) => {
        dispatch(new Message(5, [new ReportPresentation(0, [])]));
    }, ["Kurs"]), groupButtonRight(equals(p, new ReportPresentation(1, [])), (_arg_1) => {
        dispatch(new Message(5, [new ReportPresentation(1, [])]));
    }, ["Kurs og volum"])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])));
}

function subSectionTitle(title) {
    return createElement("h5", {
        style: {
            fontWeight: "bold",
        },
        children: title,
    });
}

function th$0027(hideSmall, title) {
    return MuiHelpers_createElement(TableCell, toList(delay(() => append(singleton(["variant", "head"]), delay(() => append(singleton(["size", "small"]), delay(() => append(hideSmall ? singleton(["className", "hide-small-screens"]) : empty_1(), delay(() => append(singleton(["style", {
        padding: ((((((6 + "px ") + 12) + "px ") + 6) + "px ") + 8) + "px",
    }]), delay(() => singleton(["children", title]))))))))))));
}

function drawSummary(lastQuote, x) {
    let elems_13;
    const td$0027 = (hideSmall, y, f) => {
        const z_2 = MuiHelpers_createElement(TableCell, toList(delay(() => append(singleton(["variant", "body"]), delay(() => append(singleton(["size", "small"]), delay(() => append(hideSmall ? singleton(["className", "hide-small-screens"]) : empty_1(), delay(() => append(singleton(["style", createObj(toList(delay(() => append(singleton(["padding", ((((((6 + "px ") + 12) + "px ") + 6) + "px ") + 8) + "px"]), delay(() => {
            let matchValue_1;
            const matchValue = y;
            if (matchValue != null) {
                return singleton(["color", (matchValue_1 = (f(matchValue) | 0), (matchValue_1 === 0) ? "var(--text-main)" : ((matchValue_1 < 0) ? "var(--sell)" : "var(--buy)"))]);
            }
            else {
                return empty_1();
            }
        })))))]), delay(() => {
            let elems;
            return singleton((elems = toList(delay(() => {
                if (x != null) {
                    const matchValue_2 = y;
                    return (matchValue_2 != null) ? singleton(shortPercentageToString(f(matchValue_2))) : singleton("N/A");
                }
                else {
                    return singleton(MuiHelpers_createElement(Skeleton, [["variant", "text"], ["style", {
                        height: 0.875 + "rem",
                        width: 50,
                    }]]));
                }
            })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))]));
        })))))))))));
        if (y != null) {
            return wrapTooltip(utcDateToLocalDate(y.Date), z_2);
        }
        else {
            return z_2;
        }
    };
    const summaryToEntry = (f_1) => {
        if (x != null) {
            return f_1(x);
        }
        else {
            return undefined;
        }
    };
    const textInlineLoading = () => {
        let elems_1;
        return createElement("div", createObj(ofArray([["style", {
            display: "inline-block",
        }], (elems_1 = [MuiHelpers_createElement(Skeleton, [["variant", "text"], ["style", {
            width: 40,
        }]])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])));
    };
    return createElement("div", createObj(ofArray([["style", {
        marginTop: 20,
    }], (elems_13 = toList(delay(() => append(singleton(subSectionTitle("Prosentvis utvikling")), delay(() => {
        let elements_5, children_1, children, children_4, children_2, children_3;
        let lastClose;
        if (x != null) {
            const matchValue_3 = x.Last;
            lastClose = ((matchValue_3 != null) ? utcDateToLocalDate(matchValue_3.Date) : "");
        }
        else {
            lastClose = "";
        }
        return append(singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_5 = [(children_1 = singleton_1((children = ofArray([th$0027(false, ""), th$0027(false, "1 dag"), th$0027(true, "1 uke"), th$0027(false, "1 mnd"), th$0027(false, "3 mnd"), th$0027(true, "1 år")]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi_1.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_1))]])), (children_4 = ofArray([(children_2 = ofArray([th$0027(false, "Kurs"), td$0027(false, summaryToEntry((s) => s.Prev), (q_2) => q_2.Change), td$0027(true, summaryToEntry((s_1) => s_1.Week), (q_3) => q_3.Change), td$0027(false, summaryToEntry((s_2) => s_2.Month), (q_4) => q_4.Change), td$0027(false, summaryToEntry((s_3) => s_3.Quarter), (q_5) => q_5.Change), td$0027(true, summaryToEntry((s_4) => s_4.Year), (q_6) => q_6.Change)]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_2))]])), (children_3 = ofArray([th$0027(false, "Volum"), td$0027(false, summaryToEntry((s_5) => s_5.Prev), (q_7) => q_7.VolumeChange), td$0027(true, summaryToEntry((s_6) => s_6.Week), (q_8) => q_8.VolumeChange), td$0027(false, summaryToEntry((s_7) => s_7.Month), (q_9) => q_9.VolumeChange), td$0027(false, summaryToEntry((s_8) => s_8.Quarter), (q_10) => q_10.VolumeChange), td$0027(true, summaryToEntry((s_9) => s_9.Year), (q_11) => q_11.VolumeChange)]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_3))]]))]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_4))]]))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elements_5))])])), delay(() => {
            let elems_9, elems_8;
            let patternInput;
            const ltNone = () => {
                const children_5 = ofArray(["den gjennomsnittlige sluttkursen basert på de siste 10 handelsdager på NOK ", textInlineLoading()]);
                return createElement("span", {
                    children: Interop_reactApi_1.Children.toArray(Array.from(children_5)),
                });
            };
            let matchResult, l_1, s_10;
            if (lastQuote != null) {
                if (x != null) {
                    matchResult = 1;
                    l_1 = lastQuote;
                    s_10 = x;
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0: {
                    patternInput = [textInlineLoading(), ltNone()];
                    break;
                }
                default: {
                    const matchValue_5 = s_10.AvgQuoteLastTen;
                    if (matchValue_5 != null) {
                        const aqlt = matchValue_5;
                        const percentDiff = shortPercentageToString(~~(100 * toPositivePercentageChange(l_1.Close, aqlt.Avg)));
                        patternInput = [createElement("span", {
                            style: createObj(toList(delay(() => append((aqlt.Avg > l_1.Close) ? singleton(["color", "var(--sell)"]) : ((aqlt.Avg < l_1.Close) ? singleton(["color", "var(--buy)"]) : empty_1()), delay(() => singleton(["fontWeight", "bold"])))))),
                            children: (aqlt.Avg > l_1.Close) ? (`${percentDiff} under`) : ((aqlt.Avg < l_1.Close) ? (`${percentDiff} over`) : "lik"),
                        }), createElement("span", {
                            children: [`den gjennomsnittlige sluttkursen basert på de siste 10 handelsdager på NOK ${quoteToString(aqlt.Avg)}`],
                        })];
                    }
                    else {
                        patternInput = [textInlineLoading(), ltNone()];
                    }
                }
            }
            return append(singleton(createElement("div", createObj(ofArray([["style", {}], (elems_9 = [createElement("p", createObj(singleton_1((elems_8 = toList(delay(() => {
                let matchValue_6;
                return append((matchValue_6 = lastQuote, (matchValue_6 != null) ? singleton(`Sluttkurs NOK ${quoteToString(matchValue_6.Close)} er `) : append(singleton("Sluttkurs NOK "), delay(() => append(singleton(textInlineLoading()), delay(() => singleton(" er ")))))), delay(() => append(singleton(patternInput[0]), delay(() => append(singleton(" "), delay(() => append(singleton(patternInput[1]), delay(() => singleton(".")))))))));
            })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_8))]))))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_9))])])))), delay(() => {
                let elems_11, elems_10;
                let patternInput_1;
                const ltNone_1 = () => {
                    const children_7 = ofArray(["det gjennomsnittlig volumet basert på de siste 10 handelsdager på ", textInlineLoading(), " aksjer."]);
                    return createElement("span", {
                        children: Interop_reactApi_1.Children.toArray(Array.from(children_7)),
                    });
                };
                let matchResult_1, l_3, s_11;
                if (lastQuote != null) {
                    if (x != null) {
                        matchResult_1 = 1;
                        l_3 = lastQuote;
                        s_11 = x;
                    }
                    else {
                        matchResult_1 = 0;
                    }
                }
                else {
                    matchResult_1 = 0;
                }
                switch (matchResult_1) {
                    case 0: {
                        patternInput_1 = [textInlineLoading(), ltNone_1()];
                        break;
                    }
                    default: {
                        const matchValue_8 = s_11.AvgVolumeLastTen;
                        if (matchValue_8 != null) {
                            const av = matchValue_8;
                            const avg = ~~av.Avg | 0;
                            const percentDiff_1 = shortPercentageToString(~~(100 * toPositivePercentageChange(l_3.Volume, av.Avg)));
                            patternInput_1 = [createElement("span", {
                                style: createObj(toList(delay(() => append((avg > l_3.Volume) ? singleton(["color", "var(--sell)"]) : ((avg < l_3.Volume) ? singleton(["color", "var(--buy)"]) : empty_1()), delay(() => singleton(["fontWeight", "bold"])))))),
                                children: (avg > l_3.Volume) ? (`${percentDiff_1} under`) : ((avg < l_3.Volume) ? (`${percentDiff_1} over`) : "lik"),
                            }), createElement("span", {
                                children: [`det gjennomsnittlig volumet basert på de siste 10 handelsdager på ${toShortAmount(avg)} aksjer`],
                            })];
                        }
                        else {
                            patternInput_1 = [textInlineLoading(), ltNone_1()];
                        }
                    }
                }
                return append(singleton(createElement("div", createObj(ofArray([["style", {}], (elems_11 = [createElement("p", createObj(singleton_1((elems_10 = toList(delay(() => {
                    let matchValue_9, children_9;
                    return append((matchValue_9 = lastQuote, (matchValue_9 != null) ? singleton(`Handelsvolumet på ${toShortAmount(matchValue_9.Volume)} aksjer er `) : singleton((children_9 = ofArray(["Handelsvolumet på ", textInlineLoading(), " aksjer er "]), createElement("span", {
                        children: Interop_reactApi_1.Children.toArray(Array.from(children_9)),
                    })))), delay(() => append(singleton(patternInput_1[0]), delay(() => append(singleton(" "), delay(() => singleton(patternInput_1[1])))))));
                })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_10))]))))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_11))])])))), delay(() => {
                    let elems_12;
                    return singleton(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        marginTop: 10,
                        fontSize: Dimensions_TextSmall,
                        fontStyle: "italic",
                    }], (elems_12 = [createElement("span", {
                        children: [`Basert på siste sluttkurs ${lastClose}`],
                    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_12))])]))));
                }));
            }));
        }));
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_13))])])));
}

function drawMovingAverage(x) {
    let elems_11;
    const td$0027 = (hideSmall, y) => {
        const z = MuiHelpers_createElement(TableCell, toList(delay(() => append(singleton(["variant", "body"]), delay(() => append(singleton(["size", "small"]), delay(() => append(hideSmall ? singleton(["className", "hide-small-screens"]) : empty_1(), delay(() => append(singleton(["style", {
            padding: ((((((6 + "px ") + 12) + "px ") + 6) + "px ") + 8) + "px",
            color: "var(--text-main)",
        }]), delay(() => {
            let elems;
            return singleton((elems = toList(delay(() => {
                if (x != null) {
                    const matchValue = y;
                    return (matchValue != null) ? singleton(quoteToString(matchValue.MA)) : singleton("N/A");
                }
                else {
                    return singleton(MuiHelpers_createElement(Skeleton, [["variant", "text"], ["style", {
                        height: 0.875 + "rem",
                        width: 50,
                    }]]));
                }
            })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))]));
        })))))))))));
        if (y != null) {
            return wrapTooltip(`Per ${utcDateToLocalDate(y.LastQuote.Date)}`, z);
        }
        else {
            return z;
        }
    };
    const tdAbove = (hideSmall_1, y_2) => {
        const z_1 = MuiHelpers_createElement(TableCell, toList(delay(() => append(singleton(["variant", "body"]), delay(() => append(singleton(["size", "small"]), delay(() => append(hideSmall_1 ? singleton(["className", "hide-small-screens"]) : empty_1(), delay(() => append(singleton(["style", createObj(toList(delay(() => append(singleton(["padding", ((((((6 + "px ") + 12) + "px ") + 6) + "px ") + 8) + "px"]), delay(() => {
            let matchValue_2;
            const matchValue_1 = y_2;
            if (matchValue_1 != null) {
                const q_1 = matchValue_1;
                return singleton(["color", (matchValue_2 = q_1.MA, (matchValue_2 === q_1.LastQuote.Close) ? "var(--text-main)" : ((matchValue_2 > q_1.LastQuote.Close) ? "var(--sell)" : "var(--buy)"))]);
            }
            else {
                return singleton(["color", "var(--text-dim)"]);
            }
        })))))]), delay(() => {
            let elems_1;
            return singleton((elems_1 = toList(delay(() => {
                if (x != null) {
                    const matchValue_3 = y_2;
                    if (matchValue_3 != null) {
                        const q_2 = matchValue_3;
                        return singleton((q_2.MA > q_2.LastQuote.Close) ? "Under" : "Over");
                    }
                    else {
                        return singleton("N/A");
                    }
                }
                else {
                    return singleton(MuiHelpers_createElement(Skeleton, [["variant", "text"], ["style", {
                        height: 0.875 + "rem",
                        width: 50,
                    }]]));
                }
            })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))]));
        })))))))))));
        if (y_2 != null) {
            return wrapTooltip(`Kurs ${quoteToString(y_2.LastQuote.Close)}`, z_1);
        }
        else {
            return z_1;
        }
    };
    const summaryToEntry = (f) => {
        if (x != null) {
            return f(x);
        }
        else {
            return undefined;
        }
    };
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_11 = toList(delay(() => append(singleton(subSectionTitle("Glidende gjennomsnitt (SMA)")), delay(() => {
        let elements_6, children_1, children, children_3, children_2, children_5, children_4;
        return append(singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_6 = [(children_1 = singleton_1((children = ofArray([th$0027(false, ""), th$0027(true, "SMA 20"), th$0027(false, "SMA 50"), th$0027(false, "SMA 100"), th$0027(false, "SMA 200")]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi_1.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_1))]])), (children_3 = singleton_1((children_2 = ofArray([MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", "Gj.snitt"]]), td$0027(true, summaryToEntry((m) => m.Twenty)), td$0027(false, summaryToEntry((m_1) => m_1.Fifty)), td$0027(false, summaryToEntry((m_2) => m_2.Hundred)), td$0027(false, summaryToEntry((m_3) => m_3.TwoHundred))]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_2))]]))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_3))]])), (children_5 = singleton_1((children_4 = ofArray([MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", "Sluttkurs er"]]), tdAbove(true, summaryToEntry((m_4) => m_4.Twenty)), tdAbove(false, summaryToEntry((m_5) => m_5.Fifty)), tdAbove(false, summaryToEntry((m_6) => m_6.Hundred)), tdAbove(false, summaryToEntry((m_7) => m_7.TwoHundred))]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_4))]]))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_5))]]))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elements_6))])])), delay(() => {
            let elems_10, elems_9;
            const matchValue_4 = x;
            if (matchValue_4 != null) {
                const m_8 = matchValue_4;
                const matchValue_5 = m_8.Fifty;
                const matchValue_6 = m_8.TwoHundred;
                let matchResult, f_1, th;
                if (matchValue_6 != null) {
                    if (matchValue_5 != null) {
                        matchResult = 1;
                        f_1 = matchValue_5;
                        th = matchValue_6;
                    }
                    else {
                        matchResult = 0;
                    }
                }
                else {
                    matchResult = 0;
                }
                switch (matchResult) {
                    case 0: {
                        return empty_1();
                    }
                    default:
                        return singleton(createElement("div", createObj(ofArray([["style", {}], (elems_10 = [createElement("p", createObj(singleton_1((elems_9 = [`Siste 50 dagers glidende gjennomsnitt på NOK ${quoteToString(f_1.MA)} er `, createElement("span", {
                            style: {
                                fontWeight: "bold",
                            },
                            children: (f_1.MA > th.MA) ? "over" : ((f_1.MA < th.MA) ? "under" : "lik"),
                        }), ` 200 dagers glidende gjennomsnitt på NOK ${quoteToString(th.MA)}. Teoretisk sett er dette en `, createElement("span", {
                            style: {
                                fontWeight: "bold",
                                color: (f_1.MA <= th.MA) ? "var(--sell)" : "var(--buy)",
                            },
                            children: (f_1.MA < th.MA) ? "negativ" : "positiv",
                        }), " indikator for videre kursutvikling ifølge teknisk analyse."], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_9))]))))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_10))])]))));
                }
            }
            else {
                return singleton(MuiHelpers_createElement(Skeleton, [["variant", "text"], ["style", {
                    minHeight: 80,
                }]]));
            }
        }));
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_11))])])));
}

function drawMissingInfo() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {}], (elems = [createElement("span", {
        children: "Ingen kursdata for valgt periode",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])));
}

export function AssetQuote(assetQuoteInputProps) {
    let elems_4, elems_3;
    const ticker = assetQuoteInputProps.ticker;
    const market = assetQuoteInputProps.market;
    const assetId = assetQuoteInputProps.assetId;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(assetId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchQuotes(assetId, model_1.SelectedPeriod, dispatch);
        fetchSummary(market, ticker, dispatch);
        fetchMovingAverage(market, ticker, dispatch);
        fetchSplits(market, ticker, model_1.SelectedPeriod, dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        padding: 2 + "vw",
    }], (elems_4 = [snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(6, []));
    }), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
    }], (elems_3 = toList(delay(() => {
        let elems_2;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 20,
        }], (elems_2 = toList(delay(() => append(singleton(createElement("h2", {
            style: {
                fontSize: 2 + "rem",
                fontWeight: 500,
                margin: 0,
            },
            children: "Kursutvikling",
        })), delay(() => {
            let elements, elems;
            return (model_1.Entries == null) ? singleton(MuiHelpers_createElement(Skeleton, [["variant", "rectangular"], ["style", {
                width: 102,
                height: 48,
            }]])) : singleton(MuiHelpers_createElement(FormControl, [(elements = [MuiHelpers_createElement(InputLabel, [["variant", "standard"], ["id", "quote-period-select-label-id"], ["children", "Periode"]]), MuiHelpers_createElement(Select, [["id", "quote-period-select-id"], ["labelId", "quote-period-select-label-id"], ["label", "Periode"], ["variant", "standard"], ["value", ReportPeriod__ToInt(model_1.SelectedPeriod)], ["onChange", (e_1, _arg_2) => {
                const e = e_1.target.value;
                try {
                    const p = ReportPeriod_FromInt_Z524259A4(e);
                    dispatch(new Message(4, [p]));
                    fetchQuotes(model_1.AssetId, p, dispatch);
                    fetchSplits(market, ticker, p, dispatch);
                }
                catch (matchValue) {
                }
            }], (elems = [MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(0, []))], ["children", "Uke"]]), MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(1, []))], ["children", "1 måned"]]), MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(2, []))], ["children", "3 måneder"]]), MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(3, []))], ["children", "Siste 6 måneder"]]), MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(4, []))], ["children", "Ett år"]]), MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(5, []))], ["children", "2 år"]])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]));
        })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let matchValue_1, x;
            return append((matchValue_1 = model_1.Entries, (matchValue_1 != null) ? ((x = matchValue_1, (x.length === 0) ? singleton(drawMissingInfo()) : singleton(drawGraph(x, model_1.Presentation, model_1.Splits, dispatch)))) : append(singleton(MuiHelpers_createElement(Skeleton, [["variant", "rectangular"], ["animation", "wave"], ["style", {
                minHeight: 210,
                height: 20 + "vh",
            }]])), delay(() => singleton(MuiHelpers_createElement(Skeleton, [["variant", "text"], ["animation", "wave"], ["style", {
                height: 32,
                marginBottom: 5,
                maxWidth: 300,
            }]]))))), delay(() => append(singleton(drawSummary(model_1.LastQuote, model_1.Summary)), delay(() => singleton(drawMovingAverage(model_1.MovingAverage))))));
        }));
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))])])));
}

