import { toString as toString_1, Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, record_type, bool_type, option_type, int32_type, string_type, union_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { GenericResponse_$reflection, Post_$reflection, Valuation_AssetKeyNumbers_$reflection, Asset_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { toShortAmountL, formatSpacedLong, utcDateToLocalDate, utcStringToTimePeriod, checkError } from "../Utils.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { some, unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_437914C6, Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { equals, createObj, uncurry2 } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { DefaultBorder, Dimensions_TextSmall } from "../Design.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { StockRating__ToTextColorCode, StockRating__ToColorCode, StockRating_FromInt_Z524259A4 } from "../Shared/Shared.js";
import { post } from "../Content/Post.js";
import { printf, toText, format } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Skeleton } from "@mui/material";
import { toFloat64, fromFloat32, toInt64 } from "../fable_modules/fable-library-js.4.17.0/BigInt.js";
import { snackError, pillButton } from "../ViewHelpers.js";
import { AssetQuote } from "./AssetQuote.js";
import { AssetInsiderTrade } from "./AssetInsiderTrade.js";
import { AssetBanner } from "../Content/Ads.js";
import { TopShort, AssetShort } from "./AssetShort.js";
import { Target, PostFeed } from "../Content/PostFeed.js";
import { fetchAssetKeyNumbers } from "../Promises.js";

class ContentView extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Feed", "Reviews", "Posts", "InsiderTrades", "Shorts"];
    }
}

function ContentView_$reflection() {
    return union_type("Investfora.Asset.ContentView", [], ContentView, () => [[], [], [], [], []]);
}

function ContentView__ToKey(this$) {
    switch (this$.tag) {
        case 1:
            return "review-view";
        case 2:
            return "posts-view";
        case 3:
            return "insider-trades-view";
        case 4:
            return "shorts-view";
        default:
            return "feed-view";
    }
}

class Model extends Record {
    constructor(Ticker, MarketTicker, AssetId, MarketId, AssetName, MarketName, AssetInfo, KeyNumbers, ErrorMsg, CurrentView, IsFavorite, MaxFavorites, ActiveReview) {
        super();
        this.Ticker = Ticker;
        this.MarketTicker = MarketTicker;
        this.AssetId = (AssetId | 0);
        this.MarketId = (MarketId | 0);
        this.AssetName = AssetName;
        this.MarketName = MarketName;
        this.AssetInfo = AssetInfo;
        this.KeyNumbers = KeyNumbers;
        this.ErrorMsg = ErrorMsg;
        this.CurrentView = CurrentView;
        this.IsFavorite = IsFavorite;
        this.MaxFavorites = MaxFavorites;
        this.ActiveReview = ActiveReview;
    }
}

function Model_$reflection() {
    return record_type("Investfora.Asset.Model", [], Model, () => [["Ticker", string_type], ["MarketTicker", string_type], ["AssetId", int32_type], ["MarketId", int32_type], ["AssetName", string_type], ["MarketName", string_type], ["AssetInfo", option_type(Asset_$reflection())], ["KeyNumbers", option_type(Valuation_AssetKeyNumbers_$reflection())], ["ErrorMsg", option_type(string_type)], ["CurrentView", ContentView_$reflection()], ["IsFavorite", option_type(bool_type)], ["MaxFavorites", bool_type], ["ActiveReview", option_type(Post_$reflection())]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AssetInfoResponse", "KeyNumbersResponse", "IsFavoriteResponse", "FavoriteResponse", "UnFavoriteResponse", "SetContentView", "ActiveResponse", "RemoveActive", "DismissMaxFavorites", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.Asset.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Asset_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Asset_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Valuation_AssetKeyNumbers_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Valuation_AssetKeyNumbers_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [bool_type, FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", bool_type]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", ContentView_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Post_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Post_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [], [], []]);
}

function init(market, ticker) {
    return new Model(ticker.toLocaleUpperCase(), market, assetid, marketid, assetname, marketname, undefined, undefined, undefined, new ContentView(0, []), undefined, false, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, "Kunne ikke laste nøkkeltall. Vennligst prøv igjen senere.", model.CurrentView, model.IsFavorite, model.MaxFavorites, model.ActiveReview);
            }
            else {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, res_1.fields[0], model.ErrorMsg, model.CurrentView, model.IsFavorite, model.MaxFavorites, model.ActiveReview);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, model.ErrorMsg, model.CurrentView, res_2.fields[0], model.MaxFavorites, model.ActiveReview);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                const err = res_3.fields[0];
                let matchResult, y_1;
                if (err.tag === 2) {
                    if ((err.fields[0].status) === 403) {
                        matchResult = 0;
                        y_1 = err.fields[0];
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, model.ErrorMsg, model.CurrentView, model.IsFavorite, true, model.ActiveReview);
                    default: {
                        checkError(err);
                        return model;
                    }
                }
            }
            else if (res_3.fields[0].Result === "success") {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, model.ErrorMsg, model.CurrentView, true, model.MaxFavorites, model.ActiveReview);
            }
            else {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, "Kunne ikke legge til som favoritt. Vennligst prøv igjen senere.", model.CurrentView, model.IsFavorite, model.MaxFavorites, model.ActiveReview);
            }
        }
        case 4: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                checkError(res_4.fields[0]);
                return model;
            }
            else if (res_4.fields[0].Result === "success") {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, model.ErrorMsg, model.CurrentView, false, model.MaxFavorites, model.ActiveReview);
            }
            else {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, "Kunne ikke fjerne fra favoritter. Vennligst prøv igjen senere..", model.CurrentView, model.IsFavorite, model.MaxFavorites, model.ActiveReview);
            }
        }
        case 6: {
            const res_5 = msg.fields[0];
            if (res_5.tag === 0) {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, model.ErrorMsg, model.CurrentView, model.IsFavorite, model.MaxFavorites, res_5.fields[0]);
            }
            else {
                return model;
            }
        }
        case 7:
            return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, model.ErrorMsg, model.CurrentView, model.IsFavorite, model.MaxFavorites, undefined);
        case 5:
            return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, model.ErrorMsg, msg.fields[0], model.IsFavorite, model.MaxFavorites, model.ActiveReview);
        case 8:
            return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, model.ErrorMsg, model.CurrentView, model.IsFavorite, false, model.ActiveReview);
        case 9:
            return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, undefined, model.CurrentView, model.IsFavorite, model.MaxFavorites, model.ActiveReview);
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, model.AssetInfo, model.KeyNumbers, "Kunne ikke laste informasjon om aksje.", model.CurrentView, model.IsFavorite, model.MaxFavorites, model.ActiveReview);
            }
            else {
                return new Model(model.Ticker, model.MarketTicker, model.AssetId, model.MarketId, model.AssetName, model.MarketName, res.fields[0], model.KeyNumbers, model.ErrorMsg, model.CurrentView, model.IsFavorite, model.MaxFavorites, model.ActiveReview);
            }
        }
    }
}

function fetchAssetInfo(marketTicker, assetTicker, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(Asset_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/asset/${marketTicker}/${assetTicker}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Asset_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchFavoriteState(assetId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(bool_type, undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/favorite/${assetId}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(bool_type, unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(2, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function favorite(assetId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(""), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/favorite/${assetId}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(3, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function unFavorite(assetId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["DELETE"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/favorite/${assetId}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(4, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchActive(assetId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(Post_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/review/active/${assetId}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Post_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(6, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function drawAssetInfo(model, dispatch) {
    let elems_4, elems, elems_3;
    const separator = () => createElement("span", {
        style: {
            fontSize: Dimensions_TextSmall,
        },
        children: "-",
    });
    return createElement("div", createObj(ofArray([["style", {
        textAlign: "left",
        marginTop: 3.5 + "vh",
        padding: 2 + "vw",
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
    }], (elems_4 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-between",
    }], (elems = [createElement("h1", {
        style: {
            marginBottom: 0,
            marginTop: 0,
            fontSize: 2 + "rem",
            fontWeight: "bold",
        },
        children: model.AssetName,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "baseline",
        gap: ((0 + "px ") + 10) + "px",
    }], (elems_3 = toList(delay(() => append(singleton_1(createElement("span", {
        children: [model.Ticker],
    })), delay(() => append(singleton_1(separator()), delay(() => {
        let matchValue, ai_1, elems_1;
        return append((matchValue = model.AssetInfo, (matchValue != null) ? ((matchValue.Delisted != null) ? ((ai_1 = matchValue, singleton_1(createElement("span", {
            children: [`Unotert (tidligere ${model.MarketName})`],
        })))) : singleton_1(createElement("a", createObj(ofArray([["href", `/market/${model.MarketTicker}`], (elems_1 = [createElement("span", {
            style: {
                textDecorationLine: "underline",
            },
            children: model.MarketName,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))) : singleton_1(createElement("a", createObj(ofArray([["href", `/market/${model.MarketTicker}`], (elems_1 = [createElement("span", {
            style: {
                textDecorationLine: "underline",
            },
            children: model.MarketName,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))), delay(() => {
            if (isAuthenticated) {
                const matchValue_1 = model.IsFavorite;
                if (matchValue_1 != null) {
                    const isFavorite = matchValue_1;
                    return append(singleton_1(separator()), delay(() => {
                        let elems_2;
                        return singleton_1(createElement("div", createObj(ofArray([["style", {
                            textAlign: "center",
                            padding: 0,
                            border: "none",
                            fontSize: 1 + "rem",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "baseline",
                            background: "none",
                            gap: ((0 + "px ") + 3) + "px",
                        }], ["onClick", (_arg) => {
                            if (isFavorite) {
                                unFavorite(model.AssetId, dispatch);
                            }
                            else {
                                favorite(model.AssetId, dispatch);
                            }
                        }], (elems_2 = [createElement("i", createObj(toList(delay(() => append(singleton_1(["style", createObj(toList(delay(() => (isFavorite ? singleton_1(["color", "#FF0000"]) : singleton_1(["color", "var(--text-main)"])))))]), delay(() => (isFavorite ? singleton_1(["className", "fas fa-heart"]) : singleton_1(["className", "far fa-heart"])))))))), createElement("span", {
                            style: {
                                fontSize: Dimensions_TextSmall,
                                marginTop: 2,
                            },
                            children: isFavorite ? "Favoritt" : "Merk som favoritt",
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))));
                    }));
                }
                else {
                    return empty_1();
                }
            }
            else {
                return empty_1();
            }
        }));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

function DrawActiveReview(drawActiveReviewInputProps) {
    let elems_7;
    const dispatch = drawActiveReviewInputProps.dispatch;
    const x = drawActiveReviewInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const summaryView = patternInput[0];
    const rating = StockRating_FromInt_Z524259A4(x.Rating);
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_7 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            paddingBottom: 5,
            fontSize: 0.8 + "rem",
            border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
            maxWidth: 250,
            "border-bottom": "none",
            "border-top-left-radius": `${5}px`,
            "border-top-right-radius": `${5}px`,
            padding: 5,
            backgroundColor: "#FFFFFF",
            marginBottom: -1,
            zIndex: 10,
        }], (elems = [createElement("span", {
            children: ["Du har en aktiv analyse på denne aksjen"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_5, elems_1, elems_4;
            return append(!summaryView ? singleton_1(post(x, (_arg) => {
                dispatch(new Message(7, []));
            })) : singleton_1(createElement("div", createObj(ofArray([["className", "active-review-holder"], (elems_5 = [createElement("div", createObj(ofArray([["className", "active-review-rating"], ["style", {
                backgroundColor: StockRating__ToColorCode(rating),
            }], (elems_1 = [createElement("span", {
                style: {
                    color: StockRating__ToTextColorCode(rating),
                },
                children: toString_1(rating),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-around",
            }], (elems_4 = toList(delay(() => {
                let elems_2;
                const matchValue = x.ReviewInfo;
                if (matchValue != null) {
                    const ri = matchValue;
                    const period = utcStringToTimePeriod(ri.Until);
                    return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        flexDirection: "column",
                        padding: 5,
                        paddingLeft: 15,
                        justifyContent: "center",
                    }], (elems_2 = toList(delay(() => {
                        const matchValue_1 = ri.TargetPrice;
                        if (matchValue_1 != null) {
                            const tp = matchValue_1;
                            return append(singleton_1(createElement("span", {
                                style: {
                                    color: "var(--text-dim)",
                                    fontSize: 0.7 + "rem",
                                },
                                children: "Kursmål",
                            })), delay(() => {
                                let arg_1;
                                return singleton_1(createElement("span", {
                                    style: {
                                        whiteSpace: "nowrap",
                                    },
                                    children: (arg_1 = format('{0:' + "0.00" + '}', tp), toText(printf("%s %s"))(ri.Currency)(arg_1)),
                                }));
                            }));
                        }
                        else {
                            return singleton_1(createElement("span", {
                                style: {
                                    fontSize: 0.8 + "rem",
                                },
                                children: "Ingen kursmål",
                            }));
                        }
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                        let elems_3;
                        return singleton_1(createElement("div", createObj(ofArray([["style", {
                            display: "flex",
                            alignItems: "center",
                        }], (elems_3 = [createElement("span", {
                            style: {
                                fontSize: 0.8 + "rem",
                            },
                            children: period,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
                    }));
                }
                else {
                    return empty_1();
                }
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                let elems_6;
                return singleton_1(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: 5,
                }], (elems_6 = [createElement("button", {
                    className: "pill-button-small",
                    children: summaryView ? "Vis full analyse" : "Vis oppsummering",
                    onClick: (_arg_1) => {
                        patternInput[1](!summaryView);
                    },
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

function drawKeyNumbers(model) {
    let elems_1, arg_1, arg_3, arg_5, arg_7;
    const keyNumber = (x) => createElement("span", {
        style: {
            fontSize: 2 + "rem",
            fontWeight: 500,
            fontStyle: "italic",
        },
        children: x,
    });
    const extraInfo = (x_1) => createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
            color: "var(--text-dim)",
        },
        children: x_1,
    });
    const keyBox = (title, x_2) => {
        let elems;
        return createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
            padding: 1 + "vw",
            backgroundColor: "#FFFFFF",
            borderRadius: 5,
            border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        }], (elems = toList(delay(() => append(singleton_1(createElement("span", {
            style: {
                fontSize: 1 + "rem",
                fontWeight: 500,
            },
            children: title,
        })), delay(() => ((model.KeyNumbers == null) ? append(singleton_1(MuiHelpers_createElement(Skeleton, [["width", 100], ["height", 48], ["variant", "text"]])), delay(() => singleton_1(MuiHelpers_createElement(Skeleton, [["width", 100], ["height", 19], ["variant", "text"]])))) : x_2))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    };
    let currency;
    const matchValue = model.AssetInfo;
    currency = ((matchValue != null) ? (` ${matchValue.Currency}`) : "");
    return createElement("div", createObj(ofArray([["className", "default-gap"], ["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
    }], (elems_1 = [(arg_1 = toList(delay(() => {
        const matchValue_1 = model.KeyNumbers;
        if (matchValue_1 != null) {
            const kn = matchValue_1;
            return append(singleton_1(keyNumber(format('{0:' + "0.00" + '}', kn.LastQuote.Price))), delay(() => singleton_1(extraInfo(`Per ${utcDateToLocalDate(kn.LastQuote.Date)}`))));
        }
        else {
            return empty_1();
        }
    })), keyBox("Siste sluttkurs", arg_1)), (arg_3 = toList(delay(() => {
        const matchValue_2 = model.KeyNumbers;
        if (matchValue_2 != null) {
            return singleton_1(keyNumber(formatSpacedLong(matchValue_2.NumShares)));
        }
        else {
            return empty_1();
        }
    })), keyBox("Antall aksjer", arg_3)), (arg_5 = toList(delay(() => {
        const matchValue_3 = model.KeyNumbers;
        if (matchValue_3 != null) {
            const kn_2 = matchValue_3;
            return append(singleton_1(keyNumber(`${toShortAmountL(toInt64(fromFloat32(kn_2.LastQuote.Price * toFloat64(kn_2.NumShares))))}`)), delay(() => singleton_1(extraInfo(`Per sluttkurs ${utcDateToLocalDate(kn_2.LastQuote.Date)}`))));
        }
        else {
            return empty_1();
        }
    })), keyBox(`Markedsverdi${currency}`, arg_5)), (arg_7 = toList(delay(() => {
        let p;
        const matchValue_4 = model.KeyNumbers;
        if (matchValue_4 != null) {
            const matchValue_5 = matchValue_4.LastShort;
            if (matchValue_5 != null) {
                const ls = matchValue_5;
                return append(singleton_1(keyNumber((p = (ls.Percentage / 100), format('{0:' + "0.00%" + '}', p)))), delay(() => singleton_1(extraInfo(`Siste ${utcDateToLocalDate(ls.Date)}`))));
            }
            else {
                return append(singleton_1(keyNumber("N/A")), delay(() => singleton_1(extraInfo("Ingen short data"))));
            }
        }
        else {
            return empty_1();
        }
    })), keyBox("Andel short", arg_7))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function drawDelistedInfo() {
    let elems, children;
    return createElement("div", createObj(ofArray([["style", {
        textAlign: "left",
        padding: 2 + "vw",
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
    }], (elems = [(children = ofArray([createElement("i", {
        style: {
            marginRight: 10,
            color: "#FF8C00",
            fontSize: 20 + "px",
        },
        className: "fas fa-info-circle",
    }), "Denne aksjen er ikke lengre notert. Du kan fortsatt se tidligere data og historikk på denne siden."]), createElement("p", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function maxFavorites(dispatch) {
    let elements_2, children, text_1, elems_1, elems, children_1;
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        dispatch(new Message(8, []));
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Max antall favorittaksjer nådd"]]), (children = ofArray([(text_1 = "Du har nådd max antall favorittaksjer uten aksje.io+ abonnement. Abonner på aksje.io+ og få muligheten til å legge til ubegrenset antall favoritter. Du kan også fjerne andre favorittaksjer.", MuiHelpers_createElement(DialogContentText, [["children", text_1]])), MuiHelpers_createElement(DialogContentText, [["children", "Last ned aksje.io appen for å komme i gang!"]]), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: 10 + "px ",
        marginTop: 10,
    }], (elems_1 = [createElement("a", createObj(ofArray([["style", {
        lineHeight: 0 + "px",
    }], ["href", "https://apps.apple.com/app/aksje-io/id6448765436"], ["aria-label", "Last ned aksje.io fra Apple App Store"], (elems = [createElement("img", {
        src: "/images/app_store_download.svg",
        target: "_blank",
        alt: "Lenke til aksje.io på Apple App Store",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton(pillButton(empty(), "Lukk", (_arg_2) => {
        dispatch(new Message(8, []));
    })), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function drawAppInfo(ai) {
    let elems_1, elems;
    const info = `Få push-varslinger ved nye innsidehandler og short-oppdateringer${(ai != null) ? (` for ${ai.Name}`) : ""}. Last ned aksje.io appen!`;
    return createElement("div", createObj(ofArray([["className", "default-gap"], ["style", createObj(ofArray([["display", "flex"], ["justifyContent", "space-around"], ["alignItems", "center"], ["backgroundColor", "#FFFFFF"], ["borderRadius", 5], DefaultBorder, ["padding", 1 + "vw"], ["textAlign", "left"]]))], (elems_1 = [createElement("span", {
        children: [info],
    }), createElement("a", createObj(ofArray([["style", {
        lineHeight: 0 + "px",
    }], ["href", "https://apps.apple.com/app/aksje-io/id6448765436"], ["aria-label", "Last ned aksje.io fra Apple App Store"], (elems = [createElement("img", {
        src: "/images/app_store_download.svg",
        target: "_blank",
        alt: "Lenke til aksje.io på Apple App Store",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function view(model, dispatch) {
    let elems_4;
    return createElement("div", createObj(ofArray([["className", "asset-column default-gap"], (elems_4 = toList(delay(() => append(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(9, []));
    })), delay(() => append(model.MaxFavorites ? singleton_1(maxFavorites(dispatch)) : empty_1(), delay(() => append(singleton_1(drawAssetInfo(model, dispatch)), delay(() => {
        let matchValue, ai_1;
        return append((matchValue = model.AssetInfo, (matchValue != null) ? ((matchValue.Delisted != null) ? ((ai_1 = matchValue, singleton_1(drawDelistedInfo()))) : (empty_1())) : (empty_1())), delay(() => append(singleton_1(drawKeyNumbers(model)), delay(() => append(singleton_1(createElement(AssetQuote, {
            assetId: assetid,
            market: model.MarketTicker,
            ticker: model.Ticker,
        })), delay(() => append(singleton_1(createElement(AssetInsiderTrade, {
            assetId: assetid,
        })), delay(() => append(singleton_1(createElement(AssetBanner, null)), delay(() => append(singleton_1(createElement(AssetShort, {
            assetId: assetid,
        })), delay(() => append(singleton_1(createElement(TopShort, {
            market: model.MarketTicker,
            ticker: model.Ticker,
        })), delay(() => append(singleton_1(drawAppInfo(model.AssetInfo)), delay(() => {
            let matchValue_1, active;
            return append((matchValue_1 = model.ActiveReview, (matchValue_1 != null) ? ((active = matchValue_1, singleton_1(createElement(DrawActiveReview, {
                x: active,
                dispatch: dispatch,
            })))) : (empty_1())), delay(() => {
                let elems_2, elems_1;
                const li$0027 = (cw, title) => createElement("li", createObj(toList(delay(() => append(singleton_1(["onClick", (_arg_1) => {
                    dispatch(new Message(5, [cw]));
                }]), delay(() => append(equals(model.CurrentView, cw) ? singleton_1(["className", "is-active"]) : empty_1(), delay(() => {
                    let elems;
                    return singleton_1((elems = [createElement("a", {
                        children: title,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]));
                }))))))));
                return append(singleton_1(createElement("div", createObj(ofArray([["className", "tab-menu"], (elems_2 = [createElement("ul", createObj(singleton((elems_1 = [li$0027(new ContentView(0, []), "Feed"), li$0027(new ContentView(1, []), "Analyser"), li$0027(new ContentView(2, []), "Innlegg"), li$0027(new ContentView(3, []), "Innsidehandel"), li$0027(new ContentView(4, []), "Short-endringer")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    let elems_3;
                    return singleton_1(createElement("div", createObj(ofArray([["key", ContentView__ToKey(model.CurrentView)], (elems_3 = toList(delay(() => {
                        const matchValue_2 = model.CurrentView;
                        return (matchValue_2.tag === 1) ? singleton_1(createElement(PostFeed, {
                            target: new Target(2, [model.AssetId]),
                            includeAds: true,
                        })) : ((matchValue_2.tag === 2) ? singleton_1(createElement(PostFeed, {
                            target: new Target(1, [model.AssetId]),
                            includeAds: true,
                        })) : ((matchValue_2.tag === 3) ? singleton_1(createElement(PostFeed, {
                            target: new Target(4, [model.AssetId]),
                            includeAds: true,
                        })) : ((matchValue_2.tag === 4) ? singleton_1(createElement(PostFeed, {
                            target: new Target(3, [model.AssetId]),
                            includeAds: true,
                        })) : singleton_1(createElement(PostFeed, {
                            target: new Target(0, [model.AssetId]),
                            includeAds: true,
                        })))));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
                }));
            }));
        }))))))))))))))));
    })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function Asset(assetInputProps) {
    const ticker = assetInputProps.ticker;
    const market = assetInputProps.market;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(market, ticker));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchAssetInfo(model_1.MarketTicker, model_1.Ticker, dispatch);
        fetchAssetKeyNumbers(assetid, (arg) => {
            dispatch(new Message(1, [arg]));
        });
        if (isAuthenticated) {
            fetchFavoriteState(assetid, dispatch);
            fetchActive(assetid, dispatch);
        }
    });
    return view(model_1, dispatch);
}

